import React from "react"
import AuthLayout from "../../components/layout/auth-layout"
import Login from "../../components/login/login"
import Spinner from "../../components/spinner/spinner"
import { navigate } from "gatsby"
import { GlobalAuthContext } from "../../context/authContext"

export default function LoginPage({ location }) {
  const { state } = React.useContext(GlobalAuthContext)
  const [isLoading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const checkAuthentication = async () => {
      if (state.isAuthenticated) {
        if (state?.user?.candidate?.id) {
          navigate("/candidate/profile/dashboard")
        } else {
          navigate("/company/profile/dashboard")
        }
      }
    }
    checkAuthentication()
    setLoading(state.isAuthenticated)
  }, [state.isAuthenticated])

  if (isLoading) {
    return <Spinner />
  }
  return (
    <AuthLayout
      location={location}
      slug={"login"}
      currentPageName={"Login"}
      currentPageLink={"/login"}
    >
      {/* <p>New Login Page</p> */}
      <Login
        location={location}
        data={{
          title: "Login",
          description: `<p>As a Candidate, your regular login will help Companies find you efficiently.<br/>
As a Company, join the competition to get your required Candidate(s).</p>`,
        }}
      />
    </AuthLayout>
  )
}
