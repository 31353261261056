import React, { useState, useContext, useEffect } from "react"

import { Link, navigate } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import endpoints from "../../constants/endpoints"
import { WDN } from "../../services/axiosInstance"

import Warning from "../../images/icons/wdn-warning.png"

import { GlobalAuthContext } from "../../context/authContext"

import "./login.scss"
import CheckBox from "../input/checkbox"
import InputTextBox from "../input/input-text-box"
import Spinner from "../spinner/spinner"
import renderElement from "../../utils/renderElement"

const Login = ({ data }) => {
  const [error, setError] = useState({})
  const [email_or_username, setEmailOrUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)

  const { state, dispatch } = useContext(GlobalAuthContext)

  const handleUsernameInputChange = email_or_username => {
    setEmailOrUsername(email_or_username)
  }

  const handlePasswordInputChange = password => {
    setPassword(password)
  }

  const handleCheckboxChange = value => {
    setRemember(value)
  }

  const handleLoginFormSubmit = async e => {
    e.preventDefault()
    if (email_or_username === "") {
      setError({ email_or_username: "Email field may not be blank." })
      return
    }
    if (password === "") {
      setError({ password: "Password field may not be blank." })
      return
    }
    setLoading(true)
    setError({})
    const data = {
      email_or_username,
      password,
    }
    try {
      const res = await WDN.post(endpoints.LOGIN_ROUTE, data)

      if (remember) {
        dispatch({
          type: "LOGIN_WITH_REMEMBER_ME",
          payload: res.data,
        })
      } else {
        dispatch({ type: "LOGIN", payload: res.data })
      }
      if (res?.data?.user?.role === 2) {
        navigate("/candidate/profile/dashboard")
      } else if (res?.data?.user?.role === 3) {
        navigate("/company/profile/dashboard")
      } else {
        navigate("/")
      }
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.status === 400 &&
        err.response.data.token &&
        err.response.data.message
      ) {
        setTimeout(() => {}, 2000)
        dispatch({
          type: "LOGIN_NOT_VERIFIED",
          payload: err.response.data.token,
        })
        setError({ message: err.response.data.message })
        navigate("/verify-email")
      } else {
        if (err.response && err.response.status === 404) {
          setError({ email_or_username: "User not found" })
        } else {
          setError(err.response.data)
        }
      }
      setLoading(false)
    }
  }

  const handleInputFocus = () => {
    setError({})
  }

  useEffect(() => {
    // let isMounted = true
    if (state.login_error) {
      setError({ message: state.login_error })
      dispatch({
        type: "LOGIN_ERROR",
        login_error: "",
      })
    }
    setTimeout(() => {
      setError({})
    }, 5000)

    // return () => {
    //   isMounted = false
    // }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) return <Spinner />
  return (
    <section className="login-page">
      <Container>
        <Row className="">
          <Col lg="6 m-auto" md="10" sm="10">
            <div className="form-container">
              <div className="form-title">
                {data?.title && (
                  <h1 dangerouslySetInnerHTML={renderElement(data?.title)}></h1>
                )}
                {data?.description && (
                  <div
                    className="form-description"
                    dangerouslySetInnerHTML={renderElement(data?.description)}
                  ></div>
                )}
              </div>
              <form onSubmit={handleLoginFormSubmit}>
                <InputTextBox
                  name="email_or_username"
                  placeholder="Enter your email"
                  label="Email"
                  onChange={handleUsernameInputChange}
                  onFocus={handleInputFocus}
                  value={email_or_username}
                  class={
                    (error.email_or_username || error.non_field_errors) &&
                    `error`
                  }
                  type="text"
                />

                <InputTextBox
                  name="password"
                  placeholder="Enter your password"
                  label="password"
                  onFocus={handleInputFocus}
                  onChange={handlePasswordInputChange}
                  class={
                    (error.password ||
                      error.email_or_username ||
                      error.non_field_errors) &&
                    `error`
                  }
                  value={password}
                  type="password"
                />
                <Row>
                  <Col>
                    {error &&
                      Object.keys(error)
                        .slice(0, 1)
                        .map((key, value) => {
                          return (
                            <div
                              key={value}
                              className="alert-block mb-4 d-flex align-items-center"
                            >
                              <img
                                src={Warning}
                                className="mr-3"
                                alt="wdn-warning"
                              />
                              {error[key]}
                            </div>
                          )
                        })}
                  </Col>
                </Row>

                <div className="d-flex mb-4 justify-content-between">
                  <CheckBox
                    label="Remember me"
                    onChange={handleCheckboxChange}
                  />
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    disabled={isLoading}
                    className="btn-custom primary w-100 custom-border rounded "
                    value={isLoading ? `Processing` : `Login`}
                  />
                </div>
              </form>
              <div>
                <p className="mb-0">
                  Don't have an account?<Link to="/signup"> Sign Up</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Login
